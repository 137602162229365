@tailwind base;
@tailwind components;
@tailwind utilities;

.simple-card-content {
  text-align-last: left;
  /* text-align */
}

* {
  transition: filter 5s ease-in-out;
  transition: position 3s ease-in-out;
  transition: box-shadow 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  /* outline: 1px solid rgba(0, 255, 17, 0.496); */
}

.smooth-scrolling {
  scroll-behavior: smooth;
}

.backward-arrow {
  z-index: 999;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #d81111;
}

.palette-gradient {
  background: rgb(75, 230, 201);
  background: linear-gradient(
    324deg,
    rgba(75, 230, 201, 1) 0%,
    rgba(253, 115, 130, 1) 50%,
    rgba(124, 73, 233, 1) 100%
  );
}

.to-transparent-bg {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(38, 44, 61, 0) 14%,
    rgba(5, 11, 31, 1) 15%,
    rgba(5, 11, 31, 1) 100%
  );
}
/* @font-face {
  font-family: "GothamHTF";
  src: local("GothamHTF-Black"),
    url(./assets/fonts/GothamHTF-Black.otf) format("opentype");
} */

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./fonts/Poppins-Light.ttf') format('woff');
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./fonts/Poppins-Regular.ttf') format('woff');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./fonts/Poppins-Medium.ttf') format('woff');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./fonts/Poppins-SemiBold.ttf') format('woff');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./fonts/Poppins-Bold.ttf') format('woff');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./fonts/Poppins-Black.ttf') format('woff');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos';
  src: local('Tiempos'),
    url('./fonts/TiemposHeadlineTest-light.otf') format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos';
  src: local('Tiempos'),
    url('./fonts/TiemposHeadlineTest-Regular.otf') format('woff');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Tiempos';
  src: local('Tiempos'),
    url('./fonts/TiemposHeadlineTest-Medium.otf') format('woff');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Tiempos';
  src: local('Tiempos'),
    url('./fonts/TiemposHeadlineTest-Bold.otf') format('woff');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Tiempos';
  src: local('Tiempos'),
    url('./fonts/TiemposHeadlineTest-Black.otf') format('woff');
  font-weight: 900;
  font-display: swap;
}

.animate-wiggle {
  -webkit-animation: wiggle 1s ease-in-out 3s both;
  animation: wiggle 1s ease-in-out 3s both;
}

@-webkit-keyframes wiggle {
  0%,
  100% {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(0deg);
    transform: translateX(50%) translateY(-50%) rotate(0deg);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }
  10% {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(2deg);
    transform: translateX(50%) translateY(-50%) rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(-4deg);
    transform: translateX(50%) translateY(-50%) rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(4deg);
    transform: translateX(50%) translateY(-50%) rotate(4deg);
  }
  80% {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(-2deg);
    transform: translateX(50%) translateY(-50%) rotate(-2deg);
  }
  90% {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(2deg);
    transform: translateX(50%) translateY(-50%) rotate(2deg);
  }
}
@keyframes wiggle {
  0%,
  100% {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(0deg);
    transform: translateX(50%) translateY(-50%) rotate(0deg);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }
  10% {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(2deg);
    transform: translateX(50%) translateY(-50%) rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(-4deg);
    transform: translateX(50%) translateY(-50%) rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(4deg);
    transform: translateX(50%) translateY(-50%) rotate(4deg);
  }
  80% {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(-2deg);
    transform: translateX(50%) translateY(-50%) rotate(-2deg);
  }
  90% {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(2deg);
    transform: translateX(50%) translateY(-50%) rotate(2deg);
  }
}
